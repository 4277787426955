.card-body{
    .nav-link.active{border:1px solid #20a8d8;border-radius:5px;}
    .nav-link{padding:0.5rem;}
}
.report-date-range{
    margin-bottom:2rem;
    span{
        display:inline-block;
        margin-right:1rem;
        &.bold{font-weight:bold;}
    }
}
.chart-wrapper{
    h1{padding-top:2rem;}
}
.calloutx{
    border:1px solid #89cf93;
    padding:1rem;
    height:90px;
    text-align:center;
    border-radius:5px;
    margin-bottom:1rem;
    h5{font-size:1rem;color:#929292;}
    .h3{margin-bottom:3rem;display:block;}
    &.dimmer{opacity:0.5;}
}
.bi-cust-nav{
    background:#fff;
    border-radius:6px;
    display:flex;
    flex-direction:column;
    overflow:hidden;
    & .nav-link{
        & .active{
            background: #36ae4a;
            color: #fff;
        }
        &:hover{
            background: #36ae4a;
            color: #fff;
        }
    }
}
.errorStyle{
font-size: 12px;
color: red;
}
.linkBlue{
    color: #20a8d8 !important;
    text-decoration: none!important;
    cursor: pointer!important;
    &:hover{
        color: #167495!important;
    text-decoration: underline!important;
    }
}